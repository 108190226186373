import React, { Component, Fragment, lazy, Suspense, useEffect } from "react";
import { injectIntl } from "react-intl";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import { positions, Provider as AlertProvider } from 'react-alert'
import store from 'tools/SimpleStore.js';
import {ErrorBoundary} from 'react-error-boundary';
import { Offline } from "react-detect-offline";
import { useQuery } from '@apollo/client/';
import _ from 'lodash';

import {
  GET_ORGANIZATIONS_BO,
} from "controllers/management/OrganizationsQueries";
import GlobalErrorContext from 'contexts/GlobalErrorContext';
import { ViewportProvider } from 'contexts/ViewportContext';

import { SelectPref } from 'routes/Home/SelectPref';

import GetUserRequest from 'controllers/GetUserRequest';

import AuthenticationValidator from 'components/AuthenticationValidator';
import ScrollToTop from 'components/ScrollToTop';
import GlobalMessage from 'components/GlobalMessage';
import { ErrorExpandable } from 'components/ErrorExpandable';
import { ErrorOffline } from 'components/ErrorOffline';
import { LoadingMessage } from 'components/LoadingMessage';
//import { GlobalError } from 'components/GlobalError';

const OnBoardingRouter = lazy(() => import('routes/OnBoarding/OnBoardingRouter'))
const DashboardRouter = lazy(() => import('routes/Dashboard/DashboardRouter'))
const BackOfficeRouter = lazy(() => import('routes/BackOffice/BackOfficeRouter'))
const InstallationRouter = lazy(() => import('routes/Installation/InstallationRouter'))
const PortalRouter = lazy(() => import('routes/Portal/PortalRouter'))

// optional cofiguration
const alertOptions = {
  position: positions.TOP_CENTER,
  timeout: 8000,
  offset: '45px',
  transition: 'scale',
  containerStyle: {
    zIndex: 2000,
  }
}


function BackOfficeRouterData(props){

  useEffect(() => {
    store('updatePageStamp', null);
    store("finalizePageStamp", null);
  });

  const { loading, error, data, refetch } = useQuery(GET_ORGANIZATIONS_BO, {
    variables: {selfUserId: props.selfUserId || _.get(window,"keycloak.tokenParsed.sub")},
    pollInterval: 10000,
  });

  if (error && !error.networkError) {
    return <ErrorExpandable error={error} content={error} />;
  }

  if(props.render){
    return props.render(Object.assign({loading, error, refetch},data))
  }
  return null
}

class BrowserRouter extends Component {

  constructor(props){
    super(props);

    this.setError = (error) => {

      let nextGlobalError = this.state.globalError;
      nextGlobalError.error = error;

      this.setState({
        nextGlobalError
      });
    };

    this.resetError = () => {
      this.setError(null);
    };

    // State also contains the updater function so it will
    // be passed down into the context provider
    this.state = {      
      globalError: {
        error: null,
        setError: this.setError,
        resetError: this.resetError,
      },
    };

    window.onbeforeunload = () => {
      console.log("Set page unloading callback");
      window.isunloading = true;
    }
  }

  render() {
    //const props = this.props;

    return(
      <Router>
        <Offline polling={{url: `https://api.${window.sentinelc_domain}/check`}}><ErrorOffline/></Offline>
        <ErrorBoundary FallbackComponent={ErrorExpandable}>
          <GlobalErrorContext.Provider value={this.state.globalError}>
            <ViewportProvider>
              <AlertProvider template={GlobalMessage} {...alertOptions}>
                <ScrollToTop selector="">
                  <Fragment>
                    <Switch>
                      <Route
                        exact
                        path="/"
                        render={props => {
                          return (
                            <AuthenticationValidator>
                              {<SelectPref connected />}
                            </AuthenticationValidator>
                          )
                        }}
                      />
                      <Route
                        path="/onboarding"
                        render={props => {                         
                          if (window.sentinelc_enable_onboarding === "false") return (<Redirect to="/dashboard" />);
                          return (
                            <main className="main-wrapper">
                              <Suspense fallback={<LoadingMessage/>}>
                                <AuthenticationValidator.AvailableOnlyLogOff>
                                  <OnBoardingRouter {...props}/>
                                </AuthenticationValidator.AvailableOnlyLogOff>
                              </Suspense>
                            </main>
                          );
                        }}
                      />
                      <Route
                        path="/support"
                        render={props => (
                          <AuthenticationValidator>
                            <main className="main-wrapper main-bo">
                              <BackOfficeRouterData {...props} render={(data) => (
                                <Suspense fallback={<LoadingMessage/>}>
                                  <BackOfficeRouter {...props} {...data}/>
                                </Suspense>
                              )}/>
                            </main>
                          </AuthenticationValidator>
                        )}
                      />
                      <Route
                        path="/portal"
                        render={props => (
                          <main className="main-wrapper">
                            <PortalRouter {...props}/>
                          </main>
                        )}
                      />
                      <AuthenticationValidator>
                        <Suspense fallback={<LoadingMessage/>}>
                          {window.sentinelc_enable_onboarding !== "false" ? (
                            <GetUserRequest
                              render={props => {
                                const {
                                  onboardingRequest,
                                  error,
                                } = props;

                                return (
                                  <React.Fragment>
                                    <Switch>
                                      <Route
                                        path="/installation"
                                        render={props => {
                                          if (!onboardingRequest || (onboardingRequest.finalized && !store("finalizePageStamp"))) return (<Redirect to="/dashboard" />);
                                          return (<InstallationRouter
                                            error={error}
                                            onboardingRequest={onboardingRequest}
                                            {...props}
                                          />)
                                        }}
                                      />
                                      {(onboardingRequest && !onboardingRequest.finalized) && (
                                        <Redirect to="/installation" />
                                      )}
                                      <Route
                                        path="/dashboard"
                                        render={props => {
                                          return <DashboardRouter {...props}/>
                                        }}
                                      />

                                      <Redirect to="/dashboard" />
                                    </Switch>
                                  </React.Fragment>
                                );
                              }}
                            />
                          ) : (
                            <Route
                              path="/dashboard"
                              render={props => {
                                return <DashboardRouter {...props}/>
                              }}
                            />
                          )}
                        </Suspense>
                      </AuthenticationValidator>

                    </Switch>
                  </Fragment>
                  {/*<GlobalError />*/}
                </ScrollToTop>
              </AlertProvider>
            </ViewportProvider>
          </GlobalErrorContext.Provider>
        </ErrorBoundary>
      </Router>
    );
  }
}

export default injectIntl(BrowserRouter);
